import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const WorksWithIndex: React.FC = () => {
  useEffect(() => {
    navigate(`/works-with/other`);
  }, []);
  return null;
};

export default WorksWithIndex;
